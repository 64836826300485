<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-5">
        <h1 class="text-center mb-3 subtitle-4 black--text"> اضافة امتحان يومي </h1>
        <h2 class="text-center mb-3 subtitle-4 black--text"> {{ $route.params.class_name }} </h2>
        <h3 class="text-center mb-3 subtitle-4 black--text"> {{ $route.params.subject }} </h3>
        <v-row>
          <v-col md="3" sm="3" xs="12" align-self="center">
            <div class="d-md-flex">
              <v-btn tile color="primary" class="ml-2" :loading="editLoading" @click="submitEdit()"> تعديل الدرجات
                <v-icon right> fa-edit </v-icon>
              </v-btn>
              <v-btn tile color="success" :loading="xlsxData.downloadLoading" @click="handleDownload"> تحميل اكسل
                <v-icon right> fa-download </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-form v-if="!studentDataLoading">
          <v-row class="mt-5">
            <v-col cols="12" md="6" class="absence__border">
              <v-row>
                <v-col cols="8">
                  <span>اسم الطالب</span>
                </v-col>
                <v-col cols="4">
                  <span>الدرجة</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="heading__second absence__border">
              <v-row>
                <v-col cols="8">
                  <span>اسم الطالب</span>
                </v-col>
                <v-col cols="4">
                  <span>الدرجة</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-for="(student, index) in studentData" :key="student.account_id" cols="12" md="6"
              class="absence__border">
              <v-row>
                <v-col cols="8">
                  <span class="student_name">{{ index + 1 + '-' + student.account_name }}</span>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="student.daily_exam_degrees.degree" label="الدرجة" placeholder="الدرجة" dense
                    outlined @keypress="isNumber($event)"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <div v-else class="d-flex justify-center pa-10">
          <v-progress-circular indeterminate color="amber"></v-progress-circular>
        </div>
      </v-card>
    </v-container>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="27.7778rem">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from '@/api/api'

export default {
  data() {
    return {
      addDailog: {
        open: false,
        loading: false,
        isFormValid: false,
        teacherLoading: false,
      },

      editLoading: false,

      loopCount: 0,

      maxDegree: +this.$route.params.maxDegree,

      isFormValid: false,

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: `${this.$route.params.class_name}`,
        autoWidth: true,
        bookType: 'xlsx',
      },

      Rules: {
        max_degree: [
          value => !!value || 'الدرجة القصوى مطلوبة',
          value => (value >= 0 && value <= this.maxDegree) || `يجب ان تكون بين 0 و ${this.maxDegree}`,
        ],
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      studentData: [],

      studentDataLoading: true,
    }
  },
  mounted() {
    this.getStudentData()
  },

  methods: {
    async getStudentData() {
      this.studentDataLoading = true

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getDailyExamsDegrees(this.$route.params.class_id, study_year, this.$route.params.exams_id)
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.studentDataLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.studentDataLoading = false
        this.studentData = response.data.results
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    async submitEdit() {
      this.editLoading = true

      const data = await this.modifyDataToUpload(this.studentData)


      const response = await Api.addDailyExamsDegrees(this.$route.params.exams_id, data)
      if (response.status === 401) {
        this.editLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.editLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.editLoading = false
        this.getStudentData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },


    async modifyDataToUpload(data) {
      let modifiedData = []
      for await (const iterator of data) {
        if (iterator.daily_exam_degrees.degree) {
          modifiedData.push({
            account_id: iterator._id,
            degree: +iterator.daily_exam_degrees.degree,
          })
        }
      }

      return modifiedData
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    handleDownload() {
      this.xlsxData.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['اسم الطالب', 'الدرجة']
        const filterVal = ['account_name', 'daily_exam_degrees.degree']

        // const { list } = this
        const data = this.formatJson(filterVal, this.studentData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
  },
}
</script>

<style>
.teacher_image_table {
  cursor: pointer;
}

.absence__content .v-messages {
  min-height: 0;
}
</style>

<style scoped>
@media (max-width: 42.2222rem) {
  .heading__second {
    display: none;
  }
}

.absence__content {
  margin: 0;
  padding: 0;
}

.absence__border {
  border: 0.0556rem solid rgb(206, 193, 193);
}

a {
  text-decoration: none;
}

.student_degrees {
  display: flex;
  width: 16.6667rem;
  justify-content: space-between;
  align-items: center;
}

.degree_text {
  width: 0.6667rem;
  border: red 0.0556rem solid;
}

.student_name {
  color: #000;
}
</style>
